<template>
    <div class="mainSpyContainer">
        <Turler></Turler>
    </div>
</template>

<script>
    import Turler from './Turler';


    export default {
        components: {
            Turler
        }
    }
</script>



